import {UpdateApiClient, UpdateFiltersOpenApi, UpdateSortOpenApi} from "../../generated"
import {UpdateType} from "./update.type"
import {PaginatedType, PaginationType} from "../../tech/actions/pagination/pagination.type"
import {mapOpenApiToUpdate, mapUpdateToOpenApi} from "./update.mapper"
import {ExtractedResponse, FetchResponse} from "../../tech/response/response.type"
import {extractError} from "../../tech/response/extract.util"

export class UpdateApi {

    constructor(private apiClient: UpdateApiClient) {
    }

    public async create(update: UpdateType): Promise<ExtractedResponse<UpdateType>> {
        try {
            return new FetchResponse(mapOpenApiToUpdate(await this.apiClient.updateCreate({
                updateOpenApi: mapUpdateToOpenApi(update)
            })))
        }
        catch (err) {
            return await extractError(err)
        }
    }

    public async delete(id: string): Promise<void> {
        await this.apiClient.updateDelete({ id })
    }

    public async get(id: string): Promise<UpdateType> {
        const update = await this.apiClient.updateGet({ id })
        return mapOpenApiToUpdate(update)
    }

    public async getAllPaginated(
        sort: UpdateSortOpenApi,
        filters?: UpdateFiltersOpenApi,
        pagination?: PaginationType
    ): Promise<PaginatedType<UpdateType>> {
        const result = await this.apiClient.updateGetAllPaginated({
            updateFiltersOpenApi: filters,
            elementsPerPage: pagination?.elementsPerPage,
            page: pagination?.page,
            sort
        })
        return {
            elements: result.updates.map(mapOpenApiToUpdate),
            total: result.total
        }
    }

    public async update(id: string, update: UpdateType): Promise<ExtractedResponse<UpdateType>> {
        try {
            const response = await this.apiClient.updateUpdate({
                id,
                updateOpenApi: mapUpdateToOpenApi(update)
            })
            return new FetchResponse(mapOpenApiToUpdate(response))
        }
        catch (err) {
            return await extractError(err)
        }
    }

}