import React, {useContext, useEffect, useState} from "react"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import tableStyles from "../../tech/table/table.module.css"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import {useNavigate} from "react-router-dom"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {DEAL_CATEGORY, DEAL_CATEGORY_NEW} from "../../paths"
import {DealCategoryType} from "./deal-category.type"
import {assembleClickEventHandlers} from "../../tech/click/click.util"

const DealCategoryOverview = () => {
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [categories, setCategories] = useState<DealCategoryType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setCategories(await fetchClient.dealCategoryApi.getAll())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <Section>
                    <SectionHeading
                        title="Deal categories"
                        subTitle={`${categories.length} deal categories`}
                        button={<Button
                            title="New Deal Category"
                            style={ButtonStyle.SECONDARY}
                            onClick={() => navigate(DEAL_CATEGORY_NEW)}
                            type="button"
                        />}
                    />
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Slug</th>
                                    <th>ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map((category, i) => (
                                    <tr
                                        key={i}
                                        {...assembleClickEventHandlers(navigate, DEAL_CATEGORY(category.id!))}
                                    >
                                        <td>{category.name}</td>
                                        <td>{category.slug}</td>
                                        <td>{category.id}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load deal categories"
                />
            )}
        </>
    )
}

export default DealCategoryOverview