import React, {FunctionComponent} from "react"
import {Controller, SubmitHandler, useForm} from "react-hook-form"
import formStyles from "../../tech/form/form.module.css"
import FormError from "../../tech/form/error.component"
import {UpdateType} from "./update.type"
import {DateTime} from "luxon"
import UpdateFileSelect from "./file-select/select.component"
import UpdateCompanyOrDeal from "./company-or-deal/company-or-deal.component"
import MarkdownEditor from "../../tech/markdown/editor.component"

type UpdateFormProps = {
    update?: UpdateType
    onSubmit: SubmitHandler<UpdateType>
    submitText: string
}

const UpdateForm: FunctionComponent<UpdateFormProps> = ({
    update,
    onSubmit,
    submitText
}) => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue
    } = useForm<UpdateType>({
        defaultValues: update
    })

    return (
        <form
            className={formStyles.form}
            onSubmit={handleSubmit(onSubmit)}
        >
            <input type="hidden" {...register("id")} />

            {update && update.id && (
                <label>
                    <span>ID:</span> {update.id}<br/>
                </label>
            )}

            <UpdateCompanyOrDeal
                control={control}
                errors={errors}
                update={update}
                setValue={setValue}
                watch={watch}
            />

            <label>
                <span>Date</span>
                <Controller
                    name="date"
                    control={control}
                    render={({field: {onChange, value}}) => {
                        const isoDate = value !== undefined ? DateTime.fromJSDate(value).toISODate() as string : undefined
                        return (
                            <input
                                type="date"
                                defaultValue={isoDate}
                                onChange={e => onChange(new Date(e.target.value))}
                            />
                        )
                    }}
                    rules={{
                        required: "Date is required."
                    }}
                />
                <FormError field="date" errors={errors}/>
            </label>

            <label>
                <span>Description</span>
                <MarkdownEditor
                    label="Text"
                    fieldId="text"
                    markdown={watch("description")}
                    onChange={(val: string) => setValue("description", val)}
                    errors={errors}
                    options={["LINK"]}
                />
                <input
                    type="hidden"
                    {...register("description", {
                        minLength: {value: 10, message: "Too short."},
                        maxLength: {value: 500, message: "Too long."}
                    })}
                />
                <FormError field="description" errors={errors}/>
            </label>

            <h3>Files</h3>
            <UpdateFileSelect
                files={watch("files") ? watch("files") : []}
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                watch={watch}
            />

            <button
                type="submit"
                className={formStyles.submitButton}
            >
                {submitText}
            </button>
        </form>
    )
}

export default UpdateForm