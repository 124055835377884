import {InvestmentType} from "./investment.type"
import {exportAsCsv} from "../../tech/csv/download"

export function exportInvestments(investments: InvestmentType[]) {
    const pathsToIgnore = [
        "deal",
        "documents",
        "investor.dealGroups",
        "investor.investorGroups",
        "investor.kyc",
        "investor.user"
    ]
    const pathsToIncludeDespiteIgnore = [
        "deal.id",
        "deal.name",
    ]
    exportAsCsv(
        "investments.csv",
        investments,
        pathsToIgnore,
        pathsToIncludeDespiteIgnore
    )
}