import React from "react"
import {NavigateFunction} from "react-router-dom"

export type ClickEvent = React.MouseEvent<any, MouseEvent>

export type ClickHandlers = {
    onClick: (event: ClickEvent) => void
    onContextMenu: (event: ClickEvent) => void
}

export function assembleClickEventHandlers(
    navigate: NavigateFunction,
    path: string
): ClickHandlers {
    return {
        onClick: (event: ClickEvent) => navigateOrOpenInNewTabOnClick(event, navigate, path),
        onContextMenu: (event: ClickEvent) => navigateOrOpenInNewTabOnRightClick(event, path)
    }
}

export function navigateOrOpenInNewTabOnClick(
    event: ClickEvent,
    navigate: NavigateFunction,
    path: string
) {
    if (event.metaKey || event.ctrlKey) {
        window.open(path, '_blank')
    } else {
        navigate(path)
    }
}

export function navigateOrOpenInNewTabOnRightClick(
    event: ClickEvent,
    path: string
) {
    event.preventDefault()
    window.open(path, '_blank')
}