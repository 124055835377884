import React, {useContext, useEffect, useMemo, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import tableStyles from "../../../tech/table/table.module.css"
import {COMPANY_CONTACT, COMPANY_CONTACT_NEW} from "../../../paths"
import {CompanyContactType} from "./contact.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import {PaginatedType} from "../../../tech/actions/pagination/pagination.type"
import {emptyPaginated, getPagination} from "../../../tech/actions/pagination/pagination.util"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToCompanyContactFiltersOpenApi} from "./filter.util"
import Actions from "../../../tech/actions/actions.component"
import {formatDatetime} from "../../../tech/format/format.util"
import {assembleClickEventHandlers} from "../../../tech/click/click.util"

const CompanyContactOverview = () => {
    const CONTACTS_PER_PAGE = 50
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [
        contacts,
        setContacts
    ] = useState<PaginatedType<CompanyContactType>>(emptyPaginated)

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(CONTACTS_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setContacts(await fetchClient.companyContactApi.getAllPaginated(
                    currentSortOption.id,
                    mapToCompanyContactFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Company Contacts"
                subTitle={`${contacts.total} company contacts`}
                button={<Button
                    title="New Company Contact"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(COMPANY_CONTACT_NEW)}
                    type="button"
                />}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "company contacts",
                    elementsPerPage: CONTACTS_PER_PAGE,
                    numberElements: contacts.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th>Types</th>
                                <th>ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {contacts.elements.map((contact, i) => (
                                <tr
                                    key={i}
                                    {...assembleClickEventHandlers(navigate, COMPANY_CONTACT(contact.id!))}
                                >
                                    <td>{contact.name}</td>
                                    <td>{formatDatetime(contact.created)}</td>
                                    <td>{formatDatetime(contact.modified)}</td>
                                    <td className={tableStyles.cellList}>
                                        {contact.types ? (
                                            <ul>
                                                {contact.types.map(type => (
                                                    <li key={`company-${contact.id}-type-${type}`}>
                                                        {type}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : "-"}
                                    </td>
                                    <td>{contact.id}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load company contacts"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default CompanyContactOverview