import React, {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import LoadingDots from "../../tech/loading/dots/dots.component"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import {FAQ_ENTRY, FAQ_ENTRY_NEW} from "../../paths"
import tableStyles from "../../tech/table/table.module.css"
import {FaqEntryType} from "./faq-entry.type"
import {compareNumbers} from "../../tech/sort/sort.util"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {assembleClickEventHandlers} from "../../tech/click/click.util"

const FaqEntryOverview = () => {
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [faqEntries, setFaqEntries] = useState<FaqEntryType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setFaqEntries(await fetchClient.faqEntryApi.getAll())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <Section>
                    <SectionHeading
                        title="FAQ entries"
                        subTitle={`${faqEntries.length} FAQ entries`}
                        button={<Button
                            title="New FAQ entry"
                            style={ButtonStyle.SECONDARY}
                            onClick={() => navigate(FAQ_ENTRY_NEW)}
                            type="button"
                        />}
                    />
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Order</th>
                                <th>Title</th>
                                <th>Category</th>
                                <th>Visibility</th>
                                <th>ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {faqEntries
                                .sort((a, b) => compareNumbers(a.order, b.order, "ASCENDING"))
                                .map((faqEntry, i) => (
                                        <tr
                                            key={i}
                                            {...assembleClickEventHandlers(navigate, FAQ_ENTRY(faqEntry.id!))}
                                        >
                                            <td>{faqEntry.order}</td>
                                            <td>{faqEntry.title}</td>
                                            <td>{faqEntry.category}</td>
                                            <td>{faqEntry.visibility}</td>
                                            <td>{faqEntry.id}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load FAQ entries"
                />
            )}
        </>
    )
}

export default FaqEntryOverview