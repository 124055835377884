import {InvestmentOpenApi} from "../../generated"
import {InvestmentType} from "./investment.type"
import {mapDealToOpenApi, mapOpenApiToDeal} from "../deal/deal.mapper"
import {mapDocumentToOpenApi, mapOpenApiToDocument} from "../document/document.mapper"
import {mapInvestorToOpenApi, mapOpenApiToInvestor} from "../investor/investor.mapper"
import {mapInvestmentFeesToOpenApi, mapOpenApiToInvestmentFees} from "./fee/fees.mapper"
import {mapExchangeRateToOpenApi, mapOpenApiToExchangeRate} from "../exchange-rate/exchange-rate.mapper"

export function mapOpenApiToInvestment(investment: InvestmentOpenApi): InvestmentType {
    return new InvestmentType({
        id: investment.id,
        created: investment.created,
        modified: investment.modified,
        netPricePerShare: investment.netPricePerShare,
        numberOfShares: investment.numberOfShares,
        amount: investment.amount,
        amountWithPlacementFee: investment.amountWithPlacementFee,
        amountWithPlacementFeeAndExchangeFee: investment.amountWithPlacementFeeAndExchangeFee,
        amountWithPlacementFeeAndExchangeFeeAtExchangeRate: investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRate,
        currency: investment.currency,
        agreed: investment.agreed,
        signed: investment.signed,
        paymentInstructionsSent: investment.paymentInstructionsSent,
        paid: investment.paid,
        deal: mapOpenApiToDeal(investment.deal),
        documents: investment.documents.map(d => mapOpenApiToDocument(d)),
        investor: mapOpenApiToInvestor(investment.investor),
        fees: mapOpenApiToInvestmentFees(investment.fees),
        shareClassNameInDealContract: investment.shareClassNameInDealContract,
        exchangeRate: investment.exchangeRate && mapOpenApiToExchangeRate(investment.exchangeRate)
    })
}

export function mapInvestmentToOpenApi(investment: InvestmentType): InvestmentOpenApi {
    return {
        id: investment.id,
        created: investment.created,
        modified: investment.modified,
        netPricePerShare: investment.netPricePerShare,
        numberOfShares: investment.numberOfShares,
        amount: investment.amount,
        amountWithPlacementFee: investment.amountWithPlacementFee,
        amountWithPlacementFeeAndExchangeFee: investment.amountWithPlacementFeeAndExchangeFee,
        amountWithPlacementFeeAndExchangeFeeAtExchangeRate: investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRate,
        currency: investment.currency!,
        agreed: investment.agreed,
        signed: investment.signed,
        paymentInstructionsSent: investment.paymentInstructionsSent,
        paid: investment.paid,
        deal: mapDealToOpenApi(investment.deal),
        documents: investment.documents.map(mapDocumentToOpenApi),
        investor: mapInvestorToOpenApi(investment.investor),
        fees: mapInvestmentFeesToOpenApi(investment.fees),
        shareClassNameInDealContract: investment.shareClassNameInDealContract,
        exchangeRate: investment.exchangeRate && mapExchangeRateToOpenApi(investment.exchangeRate),
        objectType: "Investment"
    }
}