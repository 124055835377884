export const DASHBOARD = "/"

export const ASYNCHRONOUS_ACTION_OVERVIEW = "/action/"

export const COLLECTION = (id: string) => `/collection/${id}/`

export const COLLECTION_DEVIATIONS = "/collection-deviation/"
export const COLLECTION_DEVIATION = (id: string) => `/collection-deviation/${id}/`

export const COMPANIES = "/company/"
export const COMPANY_NEW = "/company/new/"
export const COMPANY = (id: string) => `/company/${id}/`
export const COMPANY_DELETE = (id: string) => `/company/${id}/delete/`

export const COMPANY_CONTACTS = "/company-contact/"
export const COMPANY_CONTACT_NEW = "/company-contact/new/"
export const COMPANY_CONTACT = (id: string) => `/company-contact/${id}/`
export const COMPANY_CONTACT_DELETE = (id: string) => `/company-contact/${id}/delete/`

export const DEALS = "/deal/"
export const DEAL_NEW = "/deal/new/"
export const DEAL = (id: string) => `/deal/${id}/`
export const DEAL_DELETE = (id: string) => `/deal/${id}/delete/`
export const DEAL_OUTCOME = (id: string) => `/deal/${id}/outcome/`
export const DEAL_PUBLISH = (id: string) => `/deal/${id}/publish/`
export const DEAL_UNPUBLISH = (id: string) => `/deal/${id}/unpublish/`
export const DEAL_SEND_PAYMENT_INSTRUCTIONS = (id: string) => `/deal/${id}/send-payment-instructions/`

export const DEAL_CATEGORIES = "/deal-category/"
export const DEAL_CATEGORY_NEW = "/deal-category/new/"
export const DEAL_CATEGORY = (id: string) => `/deal-category/${id}/`
export const DEAL_CATEGORY_DELETE = (id: string) => `/deal-category/${id}/delete/`

export const DEAL_GEOGRAPHIES = "/deal-geography/"
export const DEAL_GEOGRAPHY_NEW = "/deal-geography/new/"
export const DEAL_GEOGRAPHY = (id: string) => `/deal-geography/${id}/`
export const DEAL_GEOGRAPHY_DELETE = (id: string) => `/deal-geography/${id}/delete/`

export const DEAL_GROUPS = "/deal-group/"
export const DEAL_GROUP_NEW = "/deal-group/new/"
export const DEAL_GROUP = (id: string) => `/deal-group/${id}/`
export const DEAL_GROUP_DELETE = (id: string) => `/deal-group/${id}/delete/`

export const FAQ_ENTRIES = "/faq-entry/"
export const FAQ_ENTRY_NEW = "/faq-entry/new/"
export const FAQ_ENTRY = (id: string) => `/faq-entry/${id}/`
export const FAQ_ENTRY_DELETE = (id: string) => `/faq-entry/${id}/delete/`

export const FILES = "/file/"
export const FILE_NEW = "/file/new/"
export const FILE = (id: string) => `/file/${id}/`
export const FILE_DELETE = (id: string) => `/file/${id}/delete/`

export const INVESTMENTS = "/investment/"
export const INVESTMENT = (id: string) => `/investment/${id}/`

export const INVESTMENTS_OF_DEAL = (dealId: string) => {
    const params = new URLSearchParams()
    params.append("deal", dealId)
    return INVESTMENTS + "?" + params.toString()
}
export const INVESTMENTS_OF_INVESTOR = (investorId: string) => {
    const params = new URLSearchParams()
    params.append("investor", investorId)
    return INVESTMENTS + "?" + params.toString()
}

export const INVESTORS = "/investor/"
export const INVESTOR_NEW = "/investor/new/"
export const INVESTOR = (id: string) => `/investor/${id}/`
export const INVESTOR_DELETE = (id: string) => `/investor/${id}/delete/`
export const INVESTOR_KYC_LOGS = (id: string) => `/investor/${id}/kyc-logs/`
export const INVESTOR_SUBSCRIPTION_LOGS = (id: string) => `/investor/${id}/subscription-logs/`

export const INVESTOR_GROUPS = "/investor-group/"
export const INVESTOR_GROUP_NEW = "/investor-group/new/"
export const INVESTOR_GROUP = (id: string) => `/investor-group/${id}/`
export const INVESTOR_GROUP_DELETE = (id: string) => `/investor-group/${id}/delete/`

export const KYC_REVIEWS = "/kyc-review/"
export const KYC_REVIEW = (id: string) => `/kyc-review/${id}/`

export const MANUAL_RECONCILIATION_BANK_ACCOUNTS = "/manual-reconciliation-bank-account/"
export const MANUAL_RECONCILIATION_BANK_ACCOUNT_NEW = "/manual-reconciliation-bank-account/new/"
export const MANUAL_RECONCILIATION_BANK_ACCOUNT = (id: string) => `/manual-reconciliation-bank-account/${id}/`
export const MANUAL_RECONCILIATION_BANK_ACCOUNT_DELETE = (id: string) => `/manual-reconciliation-bank-account/${id}/delete/`

export const MANUAL_RECONCILIATION_LOGS = "/manual-reconciliation-log/"
export const MANUAL_RECONCILIATION_LOG_NEW = "/manual-reconciliation-log/new/"
export const MANUAL_RECONCILIATION_LOG = (id: string) => `/manual-reconciliation-log/${id}/`

export const SINGLE_PURPOSE_VEHICLES = "/single-purpose-vehicle/"
export const SINGLE_PURPOSE_VEHICLE_NEW = "/single-purpose-vehicle/new/"
export const SINGLE_PURPOSE_VEHICLE = (id: string) => `/single-purpose-vehicle/${id}/`
export const SINGLE_PURPOSE_VEHICLE_DELETE = (id: string) => `/single-purpose-vehicle/${id}/delete/`

export const SYNDICATES = "/syndicate/"
export const SYNDICATE_NEW = "/syndicate/new/"
export const SYNDICATE = (id: string) => `/syndicate/${id}/`
export const SYNDICATE_DELETE = (id: string) => `/syndicate/${id}/delete/`
export const SYNDICATE_INVITATION_DELETE = (syndicateId: string, invitationId: string) => `/syndicate/${syndicateId}/invitation/${invitationId}/delete/`
export const SYNDICATE_INVITATION_NEW = (syndicateId: string) => `/syndicate/${syndicateId}/invitation/new/`
export const SYNDICATE_INVITATION_RESEND = (syndicateId: string, invitationId: string) => `/syndicate/${syndicateId}/invitation/${invitationId}/resend/`
export const SYNDICATE_MEMBERS_INVITATIONS = (syndicateId: string) => `/syndicate/${syndicateId}/members-invitations/`
export const SYNDICATE_MEMBER_REMOVE = (syndicateId: string, investorId: string) => `/syndicate/${syndicateId}/member/${investorId}/remove/`

export const UPDATES = "/update/"
export const UPDATE_NEW = "/update/new/"
export const UPDATE = (id: string) => `/update/${id}/`
export const UPDATE_DELETE = (id: string) => `/update/${id}/delete/`

export const USERS = "/user/"
export const USER = (id: string) => `/user/${id}/`

export const VALUATIONS = "/valuation/"
export const VALUATIONS_FOR_DEAL = (dealId: string) => `/valuation?dealId=${dealId}`

export const WAITLIST = "/waitlist/"
export const WAITLIST_ENTRY_NEW = "/waitlist/new/"
export const WAITLIST_ENTRY = (id: string) => `/waitlist/${id}/`
export const WAITLIST_ENTRY_DELETE = (id: string) => `/waitlist/${id}/delete/`