import React, {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import Alert from "../../../tech/alert/alert.component"
import {AlertType} from "../../../tech/alert/type.enum"
import Section from "../../../tech/section/section.component"
import SectionHeading from "../../../tech/section/section-heading.component"
import {MANUAL_RECONCILIATION_BANK_ACCOUNT, MANUAL_RECONCILIATION_BANK_ACCOUNT_NEW} from "../../../paths"
import tableStyles from "../../../tech/table/table.module.css"
import {ManualReconciliationBankAccountWithDealsType} from "./manual-reconciliation-bank-account.type"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {assembleClickEventHandlers} from "../../../tech/click/click.util"

const ManualReconciliationBankAccountOverview = () => {
    const navigate = useNavigate()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [accounts, setAccounts] = useState<ManualReconciliationBankAccountWithDealsType[]>([])

    useEffect(() => {
        const fetch = async () => {
            try {
                setAccounts(await fetchClient.reconciliationApi.getAllManualReconciliationBankAccounts())
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <Section>
                    <SectionHeading
                        title="Manual Reconciliation Bank Accounts"
                        subTitle={`${accounts.length} manual reconciliation bank accounts`}
                        button={<Button
                            title="New Manual Reconciliation Bank Account"
                            style={ButtonStyle.SECONDARY}
                            onClick={() => navigate(MANUAL_RECONCILIATION_BANK_ACCOUNT_NEW)}
                            type="button"
                        />}
                    />
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Number</th>
                                <th>Transfer Origin</th>
                                <th>Holder SPV Name</th>
                                <th>Currency</th>
                                <th>Bank Name</th>
                                <th>Number of deals</th>
                                <th>ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {accounts
                                .map((account, i) => (
                                        <tr
                                            key={i}
                                            {...assembleClickEventHandlers(navigate, MANUAL_RECONCILIATION_BANK_ACCOUNT(account.id!))}
                                        >
                                            <td>{account.number}</td>
                                            <td>{account.transferOrigin}</td>
                                            <td>{account.holderSinglePurposeVehicle.name}</td>
                                            <td>{account.currency}</td>
                                            <td>{account.bank.name}</td>
                                            <td>{account.deals.length}</td>
                                            <td>{account.id}</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load manual reconciliation bank accounts"
                />
            )}
        </>
    )
}

export default ManualReconciliationBankAccountOverview