import React, {useContext, useEffect, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import {CollectionDeviationType, mapCollectionDeviationTypeToString} from "./collection-deviation.type"
import {FetchClient} from "../../../tech/fetch/client"
import FetchContext from "../../../tech/fetch/fetch.context"
import SectionHeading from "../../../tech/section/section-heading.component"
import Section from "../../../tech/section/section.component"
import LoadingDots from "../../../tech/loading/dots/dots.component"
import Button from "../../../tech/button/button.component"
import {ButtonStyle} from "../../../tech/button/style.enum"
import {CollectionDeviationStateOpenApi} from "../../../generated"
import {AlertType} from "../../../tech/alert/type.enum"
import Alert from "../../../tech/alert/alert.component"
import tableStyles from "../../../tech/table/table.module.css"
import {COLLECTION_DEVIATION, INVESTMENT} from "../../../paths"
import ActionLink from "../../../tech/link/action.component"
import {formatAmountWithCurrency, formatDate} from "../../../tech/format/format.util"
import CollectionAmount from "./collection-amount.component"
import {assembleClickEventHandlers} from "../../../tech/click/click.util"

const CollectionDeviationOverview = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [collectionDeviations, setCollectionDeviations] = useState<CollectionDeviationType[]>([])

    const stateEnum =  searchParams.get("state") as CollectionDeviationStateOpenApi

    useEffect(() => {
        const fetch = async () => {
            try {
                setCollectionDeviations((await fetchClient.collectionApi.getAllCollectionDeviations(undefined, stateEnum)).sort(sort))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, stateEnum])

    return (
        <>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LOADED" && (
                <Section>
                    <SectionHeading
                        title={`${stateEnum === CollectionDeviationStateOpenApi.Open ? "Open" : "Completed"} Collection deviations`}
                        subTitle={`${collectionDeviations.length} open collection deviations`}
                        button={<Button
                            title={stateEnum === CollectionDeviationStateOpenApi.Completed
                                ? "Show open deviations"
                                : "Show completed deviations"}
                            style={ButtonStyle.SECONDARY}
                            onClick={() => setSearchParams({
                                state: stateEnum === CollectionDeviationStateOpenApi.Completed
                                    ? CollectionDeviationStateOpenApi.Open
                                    : CollectionDeviationStateOpenApi.Completed
                            })}
                            type="button"
                        />}
                    />
                    {collectionDeviations.length === 0 ? (
                        <>No {stateEnum === CollectionDeviationStateOpenApi.Open ? "open" : "completed"} collection deviations available yet.</>
                    ) : (
                        <div className={tableStyles.tableWrapper}>
                            <table className={tableStyles.tablePointer}>
                                <thead>
                                <tr>
                                    <th>Collection Deviation</th>
                                    <th>Type</th>
                                    <th>Created</th>
                                    <th>Modified</th>
                                    <th>ID</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {collectionDeviations.map((collectionDeviation, i) => {
                                    const handlers = assembleClickEventHandlers(navigate, COLLECTION_DEVIATION(collectionDeviation.id!))
                                    return (
                                        <tr key={i}>
                                            <td {...handlers}>
                                                <strong>Investor:</strong>{" "}
                                                {collectionDeviation.investment.investor.person?.fullName}<br/><br/>

                                                <strong>Investment amount:</strong>{" "}
                                                {collectionDeviation.investment.amountFormatted}<br/>
                                                <strong>Investment amount incl. fees:</strong>{" "}
                                                {collectionDeviation.investment.amountWithPlacementFeeAndExchangeFeeFormatted}<br/>
                                                {collectionDeviation.investment.deal.currency !== collectionDeviation.investment.currency && (
                                                    <>
                                                        <strong>Investment amount incl. fees (exchanged):</strong>{" "}
                                                        {collectionDeviation.investment.amountWithPlacementFeeAndExchangeFeeAtExchangeRateFormatted}<br/>
                                                    </>
                                                )}
                                                <br/>

                                                <CollectionAmount collections={collectionDeviation.collections}/>

                                                {collectionDeviation.manualReconciliationLogs.length > 0 && (
                                                    <>
                                                        <strong>Amount:</strong>{" "}
                                                        {formatAmountWithCurrency(
                                                            collectionDeviation.manualReconciliationLogs.reduce((partialSum, l) => partialSum + l.amount, 0),
                                                            collectionDeviation.investment.deal.currency
                                                        )}
                                                    </>
                                                )}
                                            </td>
                                            <td {...handlers}>
                                                {mapCollectionDeviationTypeToString(collectionDeviation.type)}
                                            </td>
                                            <td {...handlers}>
                                                {formatDate(collectionDeviation.created)}
                                            </td>
                                            <td {...handlers}>
                                                {formatDate(collectionDeviation.modified)}
                                            </td>
                                            <td {...handlers}>
                                                {collectionDeviation.id}
                                            </td>
                                            <td>
                                                <ActionLink
                                                    text={"Investment"}
                                                    link={INVESTMENT(collectionDeviation.investment.id)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Section>
            )}
            {state === "ERROR" && (
                <Alert
                    type={AlertType.ERROR}
                    text="Failed to load collection deviations"
                />
            )}
        </>
    )
}

export default CollectionDeviationOverview

function sort(a: CollectionDeviationType, b: CollectionDeviationType): number {
    return a.investment.id.localeCompare(b.investment.id)
        || a.type.localeCompare(b.type)
}