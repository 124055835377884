import {CurrencyOpenApi} from "../../generated"
import {round} from "./round.util"
import {DateTime, DateTimeFormatOptions} from "luxon"

export function formatAmountWithCurrency(
    value: number,
    currency: CurrencyOpenApi
): string {
    const rounded = round(value, 2)
    return new Intl.NumberFormat("en", {
        currency: currency,
        style: "currency",
        maximumFractionDigits: 2
    }).format(rounded)
}

export function formatAmountWithoutCurrency(
    value: number,
    maximumFractionDigits: number = 2,
    hideFractionDigitsIfPossible: boolean = false
) {
    const rounded = round(value, maximumFractionDigits)
    const currencyFractionDigits = getNumberFormat(
        rounded,
        CurrencyOpenApi.Usd,
        maximumFractionDigits,
        hideFractionDigitsIfPossible
    ).resolvedOptions().maximumFractionDigits
    return rounded.toLocaleString("en", {
        maximumFractionDigits: currencyFractionDigits
    })
}

export function formatDate(date: Date | undefined) {
    return date
        ? DateTime.fromJSDate(date).toFormat("dd/MM/yyyy")
        : "none"
}

export function formatDatetime(
    datetime: Date | undefined,
    withSeconds?: boolean
) {
    if (datetime === undefined) return "none"
    const format: DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        timeZoneName: "short",
        hour12: false
    }
    if (withSeconds) format["second"] = "2-digit"
    return DateTime.fromJSDate(datetime).toLocaleString(format, { locale: "en-gb" })
}

function getNumberFormat(
    value: number,
    currency: CurrencyOpenApi,
    maximumFractionDigits: number = 2,
    hideFractionDigitsIfPossible: boolean = false
) {
    return hideFractionDigitsIfPossible && value % 1 === 0
        ? new Intl.NumberFormat("en", {
            currency: currency,
            style: "currency",
            maximumFractionDigits: 0
        })
        : new Intl.NumberFormat("en", {
            currency: currency,
            style: "currency",
            maximumFractionDigits
        })
}