import React, {FunctionComponent} from "react"
import {
    BoldItalicUnderlineToggles,
    CreateLink,
    diffSourcePlugin,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    MDXEditor,
    toolbarPlugin,
    UndoRedo
} from "@mdxeditor/editor"
import "@mdxeditor/editor/style.css"
import FormError from "../form/error.component"
import {FieldErrors} from "react-hook-form"
import styles from "./editor.module.sass"

type MarkdownEditorOption = "BOLD_ITALIC_UNDERLINE"
    | "LINK"
    | "BULLET_LIST"
    | "NUMBERED_LIST"

type MarkdownEditorProps = {
    label: string
    fieldId: string
    markdown: string
    onChange: (val: string) => void
    errors: FieldErrors
    options?: MarkdownEditorOption[]
}

const MarkdownEditor: FunctionComponent<MarkdownEditorProps> = ({
    label,
    fieldId,
    markdown,
    onChange,
    errors,
    options = ["BOLD_ITALIC_UNDERLINE", "LINK", "BULLET_LIST", "NUMBERED_LIST"]
}) => {
    const lists: ("bullet"|"number")[] = []
    if (options.includes("BULLET_LIST")) lists.push("bullet")
    if (options.includes("NUMBERED_LIST")) lists.push("number")
    const plugins = [
        linkPlugin(),
        linkDialogPlugin(),
        listsPlugin(),
        diffSourcePlugin(),
        toolbarPlugin({
            toolbarContents: () => (
                <>
                    {" "}
                    <UndoRedo/>
                    {options.includes("BOLD_ITALIC_UNDERLINE") && <BoldItalicUnderlineToggles/>}
                    {options.includes("LINK") && <CreateLink/>}
                    {lists.length > 0 && <ListsToggle options={lists}/>}
                </>
            )
        })
    ]
    return (
        <div className={styles.wrapper}>
            <label>
                <span>{label}</span>
            </label>
            <div className={styles.editor}>
                <MDXEditor
                    markdown={markdown || ""}
                    plugins={plugins}
                    onChange={onChange}
                />
            </div>
            <FormError field={fieldId} errors={errors}/>
        </div>
    )
}

export default MarkdownEditor