import React, {useContext, useEffect, useMemo, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import Section from "../../tech/section/section.component"
import SectionHeading from "../../tech/section/section-heading.component"
import Button from "../../tech/button/button.component"
import {ButtonStyle} from "../../tech/button/style.enum"
import {DEAL, DEAL_NEW, INVESTMENTS_OF_DEAL} from "../../paths"
import {FetchClient} from "../../tech/fetch/client"
import FetchContext from "../../tech/fetch/fetch.context"
import {DealType, mapDealOutcomeToString, mapDealStageToString, mapDealWaitingStateToString} from "./deal.type"
import tableStyles from "../../tech/table/table.module.css"
import LoadingDots from "../../tech/loading/dots/dots.component"
import Alert from "../../tech/alert/alert.component"
import {AlertType} from "../../tech/alert/type.enum"
import ActionLink from "../../tech/link/action.component"
import {PaginatedType} from "../../tech/actions/pagination/pagination.type"
import {emptyPaginated, getPagination} from "../../tech/actions/pagination/pagination.util"
import {assembleSortOptions, getCurrentSortOption} from "./sort.util"
import {assembleFilterOptions, getCurrentFilter, mapToDealFiltersOpenApi} from "./filter.util"
import Actions from "../../tech/actions/actions.component"
import {getDealUrl} from "./deal.util"
import {formatDate} from "../../tech/format/format.util"
import {assembleClickEventHandlers} from "../../tech/click/click.util"

const DealOverview = () => {
    const DEALS_PER_PAGE = 50
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const fetchClient = useContext<FetchClient>(FetchContext)
    const [state, setState] = useState<"LOADING" | "LOADED" | "ERROR">("LOADING")
    const [deals, setDeals] = useState<PaginatedType<DealType>>(emptyPaginated())

    const sortOptions = assembleSortOptions()
    const currentSortOption = useMemo(
        () => getCurrentSortOption(sortOptions, searchParams),
        [sortOptions, searchParams]
    )

    const filters = useMemo(() => assembleFilterOptions(), [])
    const currentFilter = useMemo(
        () => getCurrentFilter(filters, searchParams),
        [filters, searchParams]
    )

    const pagination = useMemo(() => getPagination(DEALS_PER_PAGE, searchParams), [searchParams])

    useEffect(() => {
        const fetch = async () => {
            try {
                setState("LOADING")
                setDeals(await fetchClient.dealApi.getAllPaginated(
                    currentSortOption.id,
                    mapToDealFiltersOpenApi(currentFilter),
                    pagination
                ))
                setState("LOADED")
            } catch (err) {
                console.error(err)
                setState("ERROR")
            }
        }
        fetch()
    }, [fetchClient, currentFilter, currentSortOption.id, pagination])

    return (
        <Section>
            <SectionHeading
                title="Deals"
                subTitle={`${deals.total} deals`}
                button={<Button
                    title="New Deal"
                    style={ButtonStyle.SECONDARY}
                    onClick={() => navigate(DEAL_NEW)}
                    type="button"
                />}
            />
            <Actions
                filter={{
                    current: currentFilter,
                    filters
                }}
                pagination={{
                    label: "deals",
                    elementsPerPage: DEALS_PER_PAGE,
                    numberElements: deals.total
                }}
                sort={{
                    current: currentSortOption,
                    options: sortOptions
                }}
            >
                {state === "LOADING" && <LoadingDots/>}
                {state === "LOADED" && (
                    <div className={tableStyles.tableWrapper}>
                        <table className={tableStyles.tablePointer}>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Stage</th>
                                <th>Outcome / Waiting State</th>
                                <th>Invested</th>
                                <th>Final deadline</th>
                                <th>Published?</th>
                                <th>Deal groups</th>
                                <th>Investor groups</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {deals.elements.map((deal, i) => {
                                const handlers = assembleClickEventHandlers(navigate, DEAL(deal.id!))
                                return (
                                    <tr key={i}>
                                        <td {...handlers}>
                                            {deal.name.completeOnboarding}
                                        </td>
                                        <td {...handlers}>
                                            {mapDealStageToString(deal.stage)}
                                        </td>
                                        <td {...handlers}>
                                            {deal.waitingState !== undefined
                                                ? mapDealWaitingStateToString(deal.waitingState)
                                                : mapDealOutcomeToString(deal.outcome)}
                                        </td>
                                        <td {...handlers}>
                                            <small>{deal.investedAmountFormatted} (min. {deal.minTargetFormatted})</small>
                                        </td>
                                        <td {...handlers}>
                                            {formatDate(deal.finalDeadline)}
                                        </td>
                                        <td {...handlers}>
                                            {deal.published ? "yes" : "no"}
                                        </td>
                                        <td {...handlers}>
                                            <small>{deal.dealGroups.map(g => g.name).join(", ")}</small>
                                        </td>
                                        <td {...handlers}>
                                            <small>{deal.investorGroups.map(g => g.name).join(", ")}</small>
                                        </td>
                                        <td>
                                            <ActionLink
                                                text={"Deal URL"}
                                                link={getDealUrl(deal.id!)}
                                                blank
                                            />
                                            <ActionLink
                                                text={"Investments"}
                                                link={INVESTMENTS_OF_DEAL(deal.id!)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                )}
                {state === "ERROR" && (
                    <Alert
                        type={AlertType.ERROR}
                        text="Failed to load deals"
                    />
                )}
            </Actions>
        </Section>
    )
}

export default DealOverview